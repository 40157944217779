import React from "react";
import About from "../components/Demo-1/about";
import Layouts from "../layout/Layouts";
import {Helmet} from "react-helmet";
const AboutPage = () => {
  


  return (
    <Layouts header={1} footer={2}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>About - Lægeklar.dk</title>
          <meta name="description" content="" />
      </Helmet>
      <About />
    </Layouts>
  );
};

export default AboutPage;
