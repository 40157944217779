import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";


const DoctorCall = () => {
    let amount = 0;
    let request = "";
    function Localization(e)
    {
        // e.preventDefault();
        
        let date = new Date(); 
        let hh = date.getHours();

        let val = e.target.value;
        if(val=="dk")
        {
            $("#social_number").show();
            if(hh>=8 && hh<=16)
            {
               amount=25000;
                //   amount=100;
            }
            else
            {
                amount=30000;
                // amount=100;
            }
        }
        else
        {
            if(hh>=8 && hh<=16)
            {
                amount=60000;
                // amount=100;
            }
            else
            {
                amount=70000;
                // amount=100;

            }
            $("#social_number").hide();
        }

        request = {
            "order": {
            "items": [
              {
                "reference": "ref42",
                "name": "Doctor Appointment",
                "quantity": 1,
                "unit": "pcs",
                "unitPrice": 1,
                "grossTotalAmount": amount,
                "netTotalAmount": amount
              }
            ],
            "amount": amount,
            "currency": "DKK",
            "reference": "Doctor Appointment Order"
          },
            "checkout": {
                "language":"da-DK",
                "integrationType": "HostedPaymentPage",
                "returnUrl": "https://laegeklar.dk/success-message",
                "termsUrl": "https://laegeklar.dk/terms-and-conditions",
                "cancelUrl":"https://laegeklar.dk/doctor-call",
                "appearance": {
                  "textOptions": {
                    "completePaymentButtonText": "Pay"
                  }
                },
                "consumer": {
                    "shippingAddress": {
                        "country": "DNK"
                    }
                },
                "shippingCountries": [
                    {
                        "countryCode": "DNK"
                    }
                ],
                "shipping": {
                    "countries": [
                        {
                            "countryCode": "DNK"
                        }
                    ]
                },
                "countryCode": "DNK"
            },
            
            "paymentMethodsConfiguration": [
                {
                    "name": "Card",
                    "enabled": true
                },
                {
                    "name": "MobilePay",
                    "enabled": true
                }
            ],
            "paymentMethods": [
                {
                    "name": "MobilePay"
                },
                {
                    "name": "Card"
                }
            ]
        };

        
    }
    $(document).ready(function(){
        $(".sn").on('input', function(ev){
            
            //Prevent default
            ev.preventDefault();
            
            //Remove hyphens
            let input = ev.target.value.split("-").join("");
            
            //Make a new string with the hyphens
            // Note that we make it into an array, and then join it at the end
            // This is so that we can use .map() 
            input = input.split('').map(function(cur, index){
                
                //If the size of input is 6 or 8, insert dash before it
                //else, just insert input
                if(index == 6)
                    return "-" + cur;
                else
                    return cur;
            }).join('');
            
            //Return the new string
            $(this).val(input);
        });
    });

    function confirmPayment(e)
    {
        e.preventDefault();
        
        let fields = $("#user_form").serialize();
        
        localStorage.setItem("feilds",fields);
        
        const newItem ={
            function_name    :"make_payment",
            fields:fields,
            lang:"en",
            api_request:request
        };
        //process.env.REACT_APP_SERVER_URL
        axios.post(process.env.REACT_APP_SERVER_URL,newItem)
            .then(res=>{
                console.log(res.data);
                window.location = res.data;
            });
    }

    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="form-container" style={{backgroundColor:"white", padding:"20px",borderRadius:"10px"}}>
                            <form id="user_form" method="POST" onSubmit={(e)=>confirmPayment(e)}>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Name</label>
                                    <input type="text" name="user_name" className="form-control" required/>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Address</label>
                                    <input type="text" name="user_address" className="form-control" required/>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Email</label>
                                    <input type="email" name="user_email" className="form-control" required/>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Phone Number</label>
                                    <input type="text" name="user_phone" className="form-control" required/>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Pharmacy name</label>
                                    <input type="text" name="pharmacy_name" className="form-control" required/>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Date of birth</label>
                                    <input type="date" name="dob" className="form-control" required/>
                                </div>
                                
                                <div className="mb-3 col-12">
                                    <label className="form-label">Comments</label>
                                    <textarea className="form-control" name="comments"></textarea>
                                </div>
                                <div className="mb-3 col-12">
                                    <div className="row" onChange={(e)=>Localization(e)}>
                                        <div className="col-md-6 col-12">
                                            <input type="radio" value={"dk"} name="localization"  required/>
                                            <label className="mx-2">Danish</label>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <input type="radio" value={"none"} name="localization" required/>
                                            <label className="mx-2">Non-Danish</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 col-12" id="social_number">
                                   <label className='form-label'>Social Number</label>
                                   <input type='text' 
                                          name='social_number' 
                                          className='sn form-control' 
                                          maxlength="11"
                                          placeholder="999999-9999"
                                          />
                                </div>
                                <div className="row">
                                    <div className="col-12 terms_condition">
                                        <div className="mb-5 term_condition_text">
                                            <input type="checkbox" className="text-left me-1" name="term_condition"/>
                                            <span><Link className="term_link" to={{pathname:"/terms-and-conditions"}}>terms and conditios</Link></span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-12 text-center payment-btn">
                                    <button type="submit">Pay Now</button>
                                </div>
                                <div className="mt-2 row">
                                    <div className="col-12">
                                        <div className="text-center cards_row">
                                            <img className="me-2" src={process.env.PUBLIC_URL+"/images/dkk.png"} style={{width:"7%"}}/>
                                            <img className="me-2" src={process.env.PUBLIC_URL+"/images/visa.png"} style={{width:"7%"}}/>
                                            <img className="me-2" src={process.env.PUBLIC_URL+"/images/mastercard.png"} style={{width:"7%"}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="payment-container">
                            <div className="bottom-border">
                               <h5 className="h5 h5-md">Price</h5>
                               <p>
                                 New prices for our services
                               </p>
                            </div>
                            <div className="bold bottom-border">
                               <p>
                                Foreign tourists from 8-16 DKK: 600
                                <br/>
                                other times DKK: 700 <img className="me-2" src={process.env.PUBLIC_URL+"/images/dkk.png"} style={{width:"7%"}}/>
                               </p>
                            </div>
                            <div className="bold bottom-border">
                               <p>
                                Danish citizens with social security number from 8-16 DKK: 250
                                <br/>
                                other times DKK: 300 <img className="me-2" src={process.env.PUBLIC_URL+"/images/dkk.png"} style={{width:"7%"}}/>
                               </p>
                            </div>
                            
                        </div>
                        
                        {/* <div className="card-info">
                            <div className="">
                                
                                <div id="input_card_no" className="row">
                                    <div className="col-md-6 col-12">
                                        <h5 className="h5 h5-md">Card Number</h5>
                                        <span>Enter 16 digits card number</span>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div style={{display:"flex",padding:"5px"}} className="form-control">
                                        <input 
                                               id="ccn" type="tel" 
                                               inputmode="numeric" 
                                               pattern="[0-9\s]{13,16}" autocomplete="cc-number" maxlength="16" 
                                             placeholder="xxxx xxxx xxxx xxxx"/>
                                         <img style={{width:"16%"}} src={process.env.PUBLIC_URL+"/images/mastercard.png"} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="my-3 row">
                                <div className="col-md-6 col-12">
                                    <p className="form-label">CVV Number</p>
                                    <span>Enter 3 or 4 digit number</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <input 
                                    type="tel"
                                    pattern="[0-9\s]{3,4}" 
                                    autocomplete="ccv" 
                                    maxlength="4" 
                                    placeholder="xxxx"
                                    className="form-control"
                                     name="cvv"
                                     />
                                </div>
                            </div>
                            <div className="my-3 row">
                                <div className="col-md-6 col-12">
                                    <p className="form-label">Expiry Date</p>
                                    <span>Enter expiry date of card</span>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div style={{display:"flex"}}>
                                        <input type="text" placeholder="mm" className="form-control" name="exp_mondth"/>
                                        <span className="mx-2" style={{color:"gray",position:"relative",top:"10px"}}>/</span>
                                        <input type="text" placeholder="yyyy" className="form-control" name="exp_day"/>
                                    </div>
                                </div>
                            </div>
                            
                        </div> */}

                        
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DoctorCall;
