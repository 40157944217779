import React,{useEffect,useState} from "react";
import DoctorCall from "../components/Demo-1/DoctorCall";
import Layouts from "../layout/Layouts";
import {Helmet} from "react-helmet";
const DoctorCallPage = () => {
  


  return (
    <Layouts header={1} footer={2}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Doctor Call - Lægeklar.dk</title>
          <meta name="description" content="" />
      </Helmet>
      <DoctorCall />
    </Layouts>
  );
};

export default DoctorCallPage;
