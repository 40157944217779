import React,{useState,useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as  Router,Routes,Route} from "react-router-dom";






import Preloader from "./components/Preloader";
import { animation } from "./utils";

import Homepage from "./pages/demo-1"

import DoctorCallPage from "./pages/DoctorCall";
import TermConditionPage from "./pages/term_condition";
import SuccessMsgPage from "./pages/SuccessMsg";
import About from "./pages/about";
import FAQ from "./pages/faq-page";
import ServicePage from "./pages/service-page";

import DkHomepage from "./dk_pages/demo-1"
import DkTermConditionPage from "./dk_pages/term_condition";
import DkDoctorCallPage from "./dk_pages/DoctorCall";
import DkSuccessMsgPage from "./dk_pages/SuccessMsg";
import DkAbout from "./dk_pages/about";
import DkFAQ from "./dk_pages/faq-page";
import DkServicePage from "./dk_pages/services-page";

import "./styles/globals.css";
import "./styles/form.css";



const App = () =>  
{
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    animation();
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  });
  
    return (
        <Router >
            {loader && <Preloader />}
            <Routes>
                <Route path="/" exact  element={<Homepage/>} />
                <Route path="/doctor-call" element={<DoctorCallPage/>}/>
                <Route path="/terms-and-conditions" element={<TermConditionPage/>}/>
                <Route path="/success-message" element={<SuccessMsgPage/>}/>
                <Route path="/about-us" element={<About/>}/>
                <Route path="/faq" element={<FAQ/>}/>
                <Route path="/services" element={<ServicePage/>}/>

                <Route path="/dk" exact  element={<DkHomepage/>} />
                <Route path="/dk/doctor-call" element={<DkDoctorCallPage/>}/>
                <Route path="/dk/terms-and-conditions" element={<DkTermConditionPage/>}/>
                <Route path="/dk/success-message" element={<DkSuccessMsgPage/>}/>
                <Route path="/dk/about-us" element={<DkAbout/>}/>
                <Route path="/dk/faq" element={<DkFAQ/>}/>
                <Route path="/dk/services" element={<DkServicePage/>}/> 
            </Routes>
        </Router>
    );
}

export default App;
