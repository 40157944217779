import React from "react";
import axios from "axios";
const AboutContent = () => {
    
    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container about-page">
                <div className="row">
                    <div className="col-12">
                        <h4 className="h4-md">Om laegeKlar</h4>
                        <p  className=" ">
                          Velkommen til LaegeKlar, hvor din sundhedsvejledning er vores prioritet. Vi stræber efter at bringe sundhedstjenester lige til døren, præcis når du behøver det. 
                        </p>
                        <h4 className="h4-md">Vores mål</h4>
                        <p className=" ">
                          Vi arbejder på at forbedre processen for sundhedskonsultation. 
                          ved at bruge digital teknologi stræber 
                          vi efter at tilbyde en lidt mere praktisk måde at få sundhedsrådgivning på for alle, uanset hvor de er. 
                        </p>
                        <h4 className="h4-md">Hvad vi tilbyder</h4>
                        <p className=" ">
                           LaegeKlar er din personlige sundhedskanal. Med en dybde af viden og erfaring er vi klar til at adressere dine bekymringer, rådgive dig og hjælpe med helbredsspørgsmål. Blandt vores tilbud er:
                           
                        </p>
                        <ul className="dkk_list">
                            <li>
                            <strong>Umiddelbar lægeadgang:</strong> Dit helbred følger ikke en tidsplan. Derfor sikrer vores service, at du kan få forbindelse til en læge, når du har behov, uden forsinkelser. 
                            </li>
                            <li>
                            <strong>Rådgivning Efter Dine Behov:</strong> Hvad enten det er presserende bekymringer eller langsigtede helbredsspørgsmål, er vi her for at guide og rådgive.
                            </li>
                            <li>
                            <strong>Top Professionel Sundhedsvejledning:</strong> Vores læge kombinerer omfattende viden med en patient-centreret tilgang.
                            </li>
                        </ul>
                        <h4 className="h4-md">Dit helbred</h4>
                        <p className=" ">
                           Dit helbred er vores førsteprioritet. Vores kerneopgave er at sikre, at du føler dig støttet, oplyst og i sikre hænder på din sundhedsmæssige rejse. 
                        </p>
                        <h4 className="h4-md">Sådan gør du</h4>
                        <p className=" ">
                           At benytte LaegeKlar er ligetil. Giv os et kald, og vi vil tage os af dine behov, besvare dine spørgsmål og guide dig. Hvis der er behov for yderligere skridt, er vi her for at vejlede dig. 
                        </p>
                        <h4 className="h4-md">Vores tilsagn til dig</h4>
                        <ul className="dkk_list">
                            <li>Dit helbred og sikkerhed er altid i centrum for vores indsats.</li> 
                            
                           

                            <li> Vi er ved din side gennem enhver sundhedsudfordring, stor eller lille. </li>
                        </ul>
                        <p className="mt-2">
                        Tak for at gøre LaegeKlar til din partner inden for sundhedsrådgivning. Vi glæder os til at støtte dig i din stræben efter optimalt helbred. 
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutContent;
