import React from "react";
import axios from "axios";
const SuccessMsgContent = () => {
    function send_email()
    {
        let fields = localStorage.getItem("feilds");

		console.log(fields);
		
        const newItem ={
            function_name    :"send_email",
            dct_fields       :fields
        };
        //process.env.REACT_APP_SERVER_URL
        axios.post(process.env.REACT_APP_SERVER_URL,newItem)
            .then(res=>{
                console.log(res.data);
            });
    }
    send_email();
    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                        <h1>Thank You!</h1>
                        <p>
                        Your appointment have been booked succesfully
                        We are getting started with your appointment right away, and you will receive a confirmation mail shortly.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SuccessMsgContent;
