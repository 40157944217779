import { Link } from "react-router-dom";
import React from "react";

const Demo1Hero1 = () => {
    return (
        <section id="hero-1" className="bg-fixed hero-section division">
            <div className="container">
                <div className="row d-flex align-items-center">
                    {/* HERO TEXT */}
                    <div className="col-md-8 col-lg-7 col-xl-6">
                        <div className="hero-txt mb-40">
                            {/* Title */}
                            
                            <h2 className="">
                                {/* <span style={{color:"#3ca4ed"}}>Feel Free About</span>
                                <br></br>
                                <span style={{color:"#394d7f"}}>Finding Health-Center</span> */}
                            </h2>
                            {/* Text */}
                            <p>
                            Når det kommer til dit helbred, skal hjælp være tilgængelig uden besvær. 
                            Vi præsenterer en løsning, der giver dig let og hurtig adgang til 
                            kompetent lægehjælp ved sygdom og helbredsspørgsmål. 
                            Velkommen til vores unikke tilgang, hvor du altid er i direkte kontakt 
                            med en læge.
                            </p>
                            {/* Button */}
                            <Link to={{pathname:"/dk/doctor-call"}}>
                                <a className="btn btn-blue blue-hover">
                                tilkald læge
                                </a>
                            </Link>
                        </div>
                    </div>
                    {/* END HERO TEXT */}
                    {/* HERO IMAGE */}
                    <div className="col-md-4 col-lg-5 col-xl-6">
                        <div className="hero-1-img text-center">
                            <img
                                className="img-fluid"
                                src="images/doc.png"
                                alt="Doctor"
                            />
                        </div>
                    </div>
                </div>
                {/* End row */}
            </div>
            {/* End container */}
        </section>
    );
};

export default Demo1Hero1;
