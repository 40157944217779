import { Link } from "react-router-dom";
import React from "react";
const Footer3 = () => {
  return (
    <footer id="footer-3" className="wide-40 footer division">
      <div className="container">
        {/* FOOTER CONTENT */}
        <div className="row">
          {/* FOOTER INFO */}
          <div className="col-md-6 col-lg-4">
            <div className="footer-info mb-40">
              {/* Footer Logo */}
              {/* For Retina Ready displays take a image with double the amount of pixels that your image will be displayed (e.g 360 x 80  pixels) */}
              <span style={{color:"#37a5ee",fontSize:"2rem"}}>Laege</span>
              <span style={{color:"#394d7f",fontSize:"2rem"}}>klar</span>
              <span style={{fontSize:"1rem !important"}}>.dk</span>
              {/* Text */}
              <p className="p-sm mt-20">
              Hos LaegeKlar giver vi dig øjeblikkelig adgang til professionel lægehjælp og omfattende vejledning om sundhed og bekymring. Vores engagerede tilgang og helhedsorienterede pleje er kun et opkald væk. Ring til os på 28154512 for at starte din rejse mod øget velvære
              </p>
              {/* Social Icons */}
              <div className="footer-socials-links mt-20">
                <ul className="foo-socials text-center clearfix">
                  <li>
                    <a href="#" className="ico-facebook">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ico-twitter">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ico-google-plus">
                      <i className="fab fa-google-plus-g" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="ico-tumblr">
                      <i className="fab fa-tumblr" />
                    </a>
                  </li>
                  {/*
										<li><a href="#" class="ico-behance"><i class="fab fa-behance"></i></a></li>	
										<li><a href="#" class="ico-dribbble"><i class="fab fa-dribbble"></i></a></li>											
										<li><a href="#" class="ico-instagram"><i class="fab fa-instagram"></i></a></li>	
										<li><a href="#" class="ico-linkedin"><i class="fab fa-linkedin-in"></i></a></li>
										<li><a href="#" class="ico-pinterest"><i class="fab fa-pinterest-p"></i></a></li>									
										<li><a href="#" class="ico-youtube"><i class="fab fa-youtube"></i></a></li>											
										<li><a href="#" class="ico-vk"><i class="fab fa-vk"></i></a></li>
										<li><a href="#" class="ico-yelp"><i class="fab fa-yelp"></i></a></li>
										<li><a href="#" class="ico-yahoo"><i class="fab fa-yahoo"></i></a></li>
									    */}
                </ul>
              </div>
            </div>
          </div>
          {/* FOOTER CONTACTS */}
          <div className="col-md-6 col-lg-3 offset-lg-1">
            <div className="footer-box mb-40">
              {/* Title */}
              <h5 className="h5-xs">Vores lokation</h5>
              {/* Address */}
               <p className="foo-email">
                 Adresse:
                 <a className="ml-2" href="#">
                 Allersgade 1, st tv 2200 København
                 </a>
               </p>
              {/* Email */}
              <p className="foo-email mt-20">
                  Virksomhedens navn:
                  
                  <a className="ml-2" href="http://Lægeklar.dk">LaegeKlar.dk</a>
              </p>
              <p className="foo-email mt-20">
                  CVR-nummer:
                  <a href="#" className="ml-2">
                   40879625
                  </a>
              </p>
              <p className="foo-email mt-20">
              E-mail:
                <Link href="#" className="ml-2">
                  <a href="mailto:yourdomain@mail.com">info@laegeklar.dk</a>
                </Link>
              </p>
              {/* Phone */}
              <p className="foo-email mt-20">
              Telefon: 
                <a href="tel:28154512" className="ml-2">28154512</a>
              </p>
            </div>
          </div>
          {/* FOOTER LINKS */}
          
          {/* FOOTER LINKS */}
          <div className="col-md-6 col-lg-2">
            <div className="footer-links mb-40">
              {/* Title */}
              <h5 className="h5-xs">Opdage</h5>
              {/* Footer List */}
              <ul className="clearfix">
                <li>
                  <Link to={{pathname:"/dk/about-us"}}>
                    <a>Om</a>
                  </Link>
                  
                </li>
                <li>
                  <a href="#">Tjenester</a>
                </li>
                <li>
                  <Link to={{pathname:"/dk/faq"}}>
                    <a>FAQs</a>
                  </Link>
                </li>
                <li>
                  <Link to={{pathname:"/dk/terms-and-conditions"}}>
                    <a>Handelsbetingelser</a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* END FOOTER CONTENT */}
        {/* FOOTER COPYRIGHT */}
        <div className="bottom-footer">
          <div className="row">
            <div className="col-md-12">
              <p className="footer-copyright">
                © {new Date().getFullYear()} <span>LaegeKlar</span>. All Rights
                Reserved
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* End container */}
    </footer>
  );
};

export default Footer3;
