import React from "react";
import { Link } from "react-router-dom";
const TermConditionCont = () => {
    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container about-page faq">
                <div className="row">
                    <div className="col-12 tex-justify">
                        <p>
                         The following terms and conditions apply to all services provided by LaegeKlar unless other written agreements have been made between LaegeKlar and the patient. 
                        </p>
                        <h4 className="h4-md my-3">1. Information and advice</h4>
                        <p>
                          You can find descriptions of the services offered by LaegeKlar on our website www.laegeklar.dk. Please note that these details are for guidance only if you require further information or advice about our services. 
                        </p>
                        <h4 className="my-3">2. Price and VAT</h4>
                        <p>
                           LaegeKlar accepts payment with Dankort, MasterCard, Maestro, Visa, Visa Electron, JCB, and American Express. Services related to medical patient treatment are exempt from VAT. 
                        </p>
                        <h4 className="h4-md my-3">3. Data privacy policy</h4>
                        <p>
                          To deliver services and products, we require the following information: 
                        </p>
                        <ul className="dkk_list px-5 mb-3">
                            <li>Name</li>
                            <li>Address</li>
                            <li>Phone number</li>
                            <li>Email address
                            </li>
                        </ul>
                        <p>
                         Personal data is registered with LaegeKlar and stored for five years, after which it is deleted. Obtaining a personal identification number is necessary for services involving treatment according to the Law on Authorization of Healthcare Professionals and Healthcare Activities and the Announcement on Authorized Healthcare Professionals' Patient Records. According to the same legislation, patient records have a ten-year retention requirement. 
                        </p>
                        <p>
                          Accepting these terms constitutes your consent to registering the mentioned information, including the personal identification number. 
                        </p>
                        <p>
                          Access to registered medical records is limited to LaegeKlar employees. Information provided on laegeklar.dk is not disclosed to third parties. However, informing the patient's primary doctor if treatment is performed is standard practice and advisable. We respect your wishes if you express that your primary doctor should not be informed during the conversation. As registered with LaegeKlar, you always have the right to object to registering your information. You also have the right to access the information registered about you. The Data Protection Act covers these rights, and any inquiries can be sent to LaegeKlar at info@laegeklar.dk. The responsible for data processing at LaegeKlar is the director. General customer information is not stored encrypted. Journal information, including the CPR number, is processed in an encrypted medical journal system. 
                        </p>
                        <h4 className="h4-md my-3">4. Delivery time</h4>
                        <p>
                          Telephone services from LaegeKlar are considered delivered when the conversation between the patient and the doctor begins. The delivery of house calls is considered to have taken place when the doctor arrives at the patient's location. 
                        </p>
                        <h4 className="h4-md my-3">5. Use of cookies</h4>
                        <p>
                         On laegeklar.dk, we use cookies to optimize the website's functionality and ensure the most user-friendly experience. You can delete cookies from your computer anytime, depending on your internet browser. 
                        </p>
                        <h4 className="h4-md my-3">6. Log statistics</h4>
                        <p>
                          We use log statistics on laegeklar.dk, where a statistical system collects information to provide statistical data about visitors, their origin, and which parts of the website they leave. Log statistics are used exclusively to optimize laegeklar.dk. 
                        </p>
                        <h4 className="h4-md my-3">7. Right of cancellation</h4>
                        <p>
                          There is a right of cancellation. Telephone consultations and house calls are performed shortly after ordering, which means the cancellation right follows these guidelines: The cancellation period expires upon delivery, i.e., when the conversation begins. If you wish to exercise the right of cancellation for a telephone consultation, it is sufficient to inform us as soon as the doctor answers the call. For house calls, the right of cancellation applies until the doctor departs. After this time, you will be billed for the time spent on transportation. If you wish to cancel a house call, you can call the phone number provided at the time of agreement. 
                        </p>
                        <h4 className="h4-md my-3">8. Force majeure</h4>
                        <p>
                          We disclaim responsibility for failing to fulfill our obligations under the Terms if the failure is due to events beyond our reasonable control - per the rules on Force Majeure. 
                        </p>
                        <h4 className="h4-md my-3">9. Insurance</h4>
                        <p>
                          Tryg Insurance covers your treatment for all services performed during your treatment. 
                        </p>
                        <h4 className="h4-md my-3">10. Your capacity to enter into our agreements </h4>
                        <p>
                        Your capacity and age: By placing an order on the website, you confirm that You are legally capable of entering into binding agreements and are over 18 years old. 

                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermConditionCont;
