import React from "react";
import { Link } from "react-router-dom";
const TermConditionCont = () => {
    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container about-page faq">
                <div className="row">
                    <div className="col-12 tex-justify">
                        <p>
                         De nedenstående betingelser og vilkår finder anvendelse på alle ydelser, som tilbydes af LaegeKlar, medmindre der er indgået andre skriftlige aftaler mellem LaegeKlar og patienten.
                        </p>
                        <h4 className="my-3">1. Information og rådgivning</h4>
                        <p>
                         Du kan finde beskrivelser af de ydelser, som LaegeKlar tilbyder, på vores hjemmeside www.laegeklar.dk. Hvis du ønsker yderligere oplysninger eller rådgivning om vores ydelser, bedes du bemærke, at disse oplysninger er vejledende.
                        </p>
                        <h4 className="my-3">2. Pris og moms</h4>
                        <p>
                            LaegeKlar accepterer betaling med Dankort, MasterCard, Maestro, Visa, Visa Electron, JCB og American Express.
                            Ydelser, der vedrører lægelig patientbehandling, er momsfritaget.
                        </p>
                        <h4 className="my-3">3. Persondatapolitik</h4>
                        <p>
                         For at kunne levere ydelser og produkter har vi brug for følgende oplysninger:
                        </p>
                        <ul className="dkk_list px-5 mb-3">
                            <li>Navn</li>
                            <li>Adresse</li>
                            <li>Telefonnummer</li>
                            <li>E-mailadresse
                            </li>
                        </ul>
                        <p>
                            Personlige oplysninger registreres hos LaegeKlar og opbevares i fem år, hvorefter de slettes.

                            Ved ydelser, der omhandler behandling, er indhentning af personnummer nødvendig i overensstemmelse med Lov om autorisation af sundhedspersoner og om sundhedsfaglig virksomhed samt Bekendtgørelse om autoriserede sundhedspersoners patientjournaler. Ifølge samme lovgivning er der en opbevaringspligt på ti år for patientjournaler.
                        </p>
                        <p>
                          Din accept af disse betingelser udgør dit samtykke til registrering af de nævnte oplysninger, inklusive personnummer.
                        </p>
                        <p>
                            Adgang til de registrerede journaloplysninger er begrænset til medarbejdere hos LaegeKlar.

                            Oplysninger, der afgives på laegeklar.dk, videregives ikke til tredjepart. Dog er det en almindelig praksis og hensigtsmæssigt at informere patientens primære læge, hvis der udføres behandling. Vi respekterer selvfølgelig dine ønsker, hvis du under samtalen giver udtryk for, at din primære læge ikke skal informeres.

                            Som registreret hos LaegeKlar har du altid ret til at gøre indsigelse mod registreringen af dine oplysninger. Du har også ret til indsigt i de oplysninger, der er registreret om dig. Disse rettigheder er omfattet af Persondataloven, og eventuelle henvendelser i denne forbindelse kan sendes til LaegeKlar på info@laegeklar.dk.

                            Den ansvarlige for databehandling hos LaegeKlar er direktøren.

                            Almindelige kundeoplysninger opbevares ikke krypteret. Journaloplysninger, herunder CPR-nummer, behandles i et krypteret lægeligt journalsystem.
                        </p>
                        <h4 className="my-3">4. Leveringstidspunkt</h4>
                        <p>
                         Telefoniske ydelser fra LaegeKlar anses for leveret, når samtalen mellem patienten og lægen påbegyndes. Levering af sygebesøg anses for at finde sted, når lægen ankommer til patientens opholdssted.
                        </p>
                        <h4 className="my-3">5. Brug af cookies</h4>
                        <p>
                         På laegeklar.dk anvender vi cookies med det formål at optimere hjemmesidens funktionalitet for at sikre den mest brugervenlige oplevelse. Du har mulighed for at slette cookies fra din computer når som helst; processen varierer afhængigt af din internetbrowser.
                        </p>
                        <h4 className="my-3">6. Logstatistik</h4>
                        <p>
                         Vi benytter en logstatistik på laegeklar.dk, hvor et statistiksystem indsamler information med det formål at levere statistiske data om besøgende, deres oprindelse og hvilke dele af hjemmesiden, de forlader. Logstatistikken bruges udelukkende til at optimere laegeklar.dk.
                        </p>
                        <h4 className="my-3">7. Fortrydelsesret</h4>
                        <p>
                         Der gælder en fortrydelsesret. Telefonkonsultationer og sygebesøg udføres kort efter bestillingen, hvilket betyder, at fortrydelsesretten følger disse retningslinjer: Fortrydelsesfristen udløber ved levering, det vil sige når samtalen påbegyndes. Ønsker du at udnytte fortrydelsesretten for en telefonkonsultation, er det tilstrækkeligt at informere os så snart lægen besvarer opkaldet. For sygebesøg gælder fortrydelsesretten indtil lægen begiver sig afsted. Efter dette tidspunkt faktureres du for den tid, der er brugt på transport. Ønsker du at aflyse et sygebesøg, kan du blot ringe til det telefonnummer, du har fået oplyst ved aftalens indgåelse.
                        </p>
                        <h4 className="my-3">8. Force majeure</h4>
                        <p>
                        Vi fraskriver os ansvaret for ikke at opfylde vores forpligtelser i henhold til Betingelserne, hvis den manglende opfyldelse skyldes begivenheder, der er uden for vores rimelige kontrol - i overensstemmelse med reglerne om Force Majeure.
                        </p>
                        <h4 className="my-3">9. Forsikring</h4>
                        <p>
                         Din behandling er dækket af Tryg Forsikring for alle serviceydelser, der udføres i relation til din behandling.
                        </p>
                        <h4 className="my-3">10. Din evne til at indgå aftaler</h4>
                        <p>
                        Din evne og alder: Ved at placere en ordre på hjemmesiden bekræfter du, at:

                        Du juridisk er i stand til at indgå bindende aftaler; og
                        Du er over 18 år.

                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermConditionCont;
