import { Link,useLocation } from "react-router-dom";
import React, { useState,useEffect } from "react";
import MobileMenu from "./MobileMenu";



const Header1 = ({ toggleMenu, toggle }) => {
  const location = useLocation();
  const { from } = (location.state)?location.state:"";
  
  const [newUrl,setnewUrl] = useState("");

  useEffect(()=>{
    let url = window.location.href;
    let path = new URL(url).pathname;
    let index = path.split("/");
    
    
    let new_url = "";
    
    index = index[index.length-1];
    if(index!="")
    {
      new_url = "/dk/"+index;
    }
    else
    {
      new_url = "/dk";
    }
    

    setnewUrl(new_url);
  },[from])
  
  return (
    <header id="header" className="header">
      {/* MOBILE HEADER */}
      <div className="wsmobileheader clearfix">
        <Link href="#">
          <a
            id="wsnavtoggle"
            onClick={() => toggleMenu()}
            className="wsanimated-arrow"
          >
            <span />
          </a>
        </Link>
        <span className="smllogo">
           <Link to={{pathname:"/"}} style={{fontWeight:"bold"}}>
              <span style={{color:"#37a5ee",fontSize:"2rem"}}>Læge</span>
              <span style={{color:"#394d7f",fontSize:"2rem"}}>klar</span>
              <span style={{fontSize:"1rem !important"}}>.dk</span>
              
            </Link>
        </span>
       
      </div>
      {/* NAVIGATION MENU */}
      <div className="wsmainfull menu clearfix d-none d-lg-block">
        <div className="wsmainwp clearfix">
          {/* LOGO IMAGE */}
          {/* For Retina Ready displays take a image with double the amount of pixels that your image will be displayed (e.g 360 x 80 pixels) */}
          <div className="desktoplogo">
            <Link to={{pathname:"/"}} style={{fontWeight:"bold"}}>
              <span style={{color:"#37a5ee",fontSize:"2rem"}}>Laege</span>
              <span style={{color:"#394d7f",fontSize:"2rem"}}>klar</span>
              <span style={{fontSize:"1rem !important"}}>.dk</span>
              {/* <img
                src="images/logo.png"
                width={110}
                alt="header-logo"
              /> */}
            </Link>
          </div>
          {/* MAIN MENU */}
          <nav className="wsmenu clearfix">
            <ul className="wsmenu-list">
              {/* DROPDOWN MENU */}
              
              {/* NAVIGATION MENU BUTTON */}
              <li className="nl-simple" aria-haspopup="true">
                <Link to={{pathname:"/"}}>
                  <a>Home</a>
                </Link>
              </li>
              <li className="nl-simple" aria-haspopup="true">
                <Link to={{pathname:"/about-us"}}>
                  <a>About</a>
                </Link>
              </li>
              <li className="nl-simple" aria-haspopup="true">
                <Link to={{pathname:"/services"}}>
                  <a>Services</a>
                </Link>
              </li>
              <li className="nl-simple" aria-haspopup="true">
                <Link to={{pathname:"/faq"}}>
                  <a>FAQ</a>
                </Link>
              </li>
              <li className="nl-simple" aria-haspopup="true">
                <Link  to={{pathname:newUrl}}>
                  <a>Danish</a>
                </Link>
              </li>
              <li className="nl-simple header-btn" aria-haspopup="true">
                <Link to={{pathname:"/doctor-call"}}>
                  <a>Contact Us</a>
                </Link>
              </li>
            </ul>
          </nav>
          {/* END MAIN MENU */}
        </div>
      </div>
      <MobileMenu />
      {/* END NAVIGATION MENU */}
    </header>
  );
};

export default Header1;
