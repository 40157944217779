import React from "react";
import TermConditionCont from "../components/Demo-1/term_condition";
import Layouts from "../layout/Layouts";
import {Helmet} from "react-helmet";
const TermConditionPage = () => {
  


  return (
    <Layouts header={1} footer={2}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Terms And Conditions - Lægeklar.dk</title>
          <meta name="description" content="" />
      </Helmet>
      <TermConditionCont />
    </Layouts>
  );
};

export default TermConditionPage;
