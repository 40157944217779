import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
const FAQContent = () => {
    
    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container faq">
                <div className="row">
                    <div className="col-12 my-3 text-center">
                        <h2 className="page-title">Ofte stillede spørgsmål</h2>
                    </div>
                    <div className="col-12">
                        <h5 className="h5-md">Har du brug for at tale med LaegeKlar?</h5>
                        <p  className=" ">
                           Book tid her eller ring til os på 2815 4512. 
                        </p>
                        <h5 className="h5-md">Hvornår modtager jeg et opkald?</h5>
                        <p className=" ">
                          Vores team hos LaegeKlar står klar alle døgnets timer. Vi ringer tilbage hurtigst muligt. 
                        </p>
                        <h5 className="h5-md">Hvordan arrangerer jeg et besøg fra en læge?</h5>
                        <p className=" ">
                          Brug vores online formular her, eller kontakt os direkte på 2815 4512. 
                        </p>
                        <h5 className="h5-md">Skal jeg være medlem?</h5>
                        <p className=" ">
                          Medlemskab er ikke påkrævet for at benytte vores tjenester. 
                        </p>
                        <h5 className="h5-md">Hvordan håndterer jeg betalingen?</h5>
                        <p className=" ">
                          Du kan nemt betale online eller telefonisk med dit Dankort eller andre kreditkort. 
                        </p>
                        <h5 className="h5-md">Hvordan får jeg en ordrebekræftelse?</h5>
                        <p>
                          Efter din onlinebestilling sender vi en bekræftelse til din indbakke. 
                        </p>


                        <h5 className="h5-md">Hvad er prisen for en telefonsession?</h5>
                        <p>
                          For dem med et dansk CPR-nummer er prisen 400 kr fra 8-16 og 500 
                          kr udenfor disse timer. Uden et dansk CPR-nummer er prisen 750 kr 
                          fra 8-16 og 850 kr resten af dagen. 
                        </p>
                        <h5 className="h5-md">Bliver min egen læge informeret?</h5>
                        <p>
                          Som en del af LaegeKlars procedure orienterer vi typisk din faste læge om den behandling, vi tilbyder. Hvis du vil undlade dette, bedes du informere os under samtalen.  
                        </p>
                        <h5 className="h5-md">Din feedback</h5>
                        <p>
                          Vi sætter pris på din feedback. Har du forslag eller bekymringer, er du velkommen til at dele dem med os. LaegeKlar følger de officielle klageprocedurer i Styrelsen for Patientsikkerhed.  
                        </p>
                        <h5 className="h5-md">Om vores forsikring</h5>
                        <p>
                          LaegeKlar er dækket af Tryg Forsikring.  
                        </p>

                        <h5 className="h5-md">Servicevilkår</h5>
                        <p>
                        Du kan finde alle vores vilkår og betingelser <Link style={{color:"007bff !important",textDecoration:"underline"}} to={{pathname:"/dk/terms-and-conditions"}}>her</Link>  
                        </p>
                        <h5 className="h5-md">Hvorfor er CPR-nummer nødvendigt?</h5>
                        <p>
                         Lovgivningen kræver, at vi opretter en patientjournal baseret på dit navn og CPR-nummer. Alle oplysninger behandles fortroligt og sikkert.  
                        </p>
                        {/* <h5 className="h5-md">Kontakt os</h5>
                        <p>
                        For at få LaegeKlars postadresse, kan du skrive til:  
                        </p> */}
                        {/* <h5 className="h5-md">LaegeKlar</h5>
                        <p>
                          E-mail: <a href="mailto:info@laegeklar.dk">info@laegeklar.dk</a>  
                        </p>
                        <p>
                          Telefon: <a href="tel:28154512">2815 4512</a> 
                        </p> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQContent;
