import React from "react";
import { Link } from "react-router-dom";
const FAQContent = () => {
    
    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container faq">
                <div className="row">
                    <div className="col-12 my-3 text-center">
                        <h2 className="page-title">Frequently asked questions</h2>
                    </div>
                    <div className="col-12">
                        <h5 className="h5-md">Need to speak with LaegeKlar?</h5>
                        <p  className=" ">
                          Book an appointment here or call us at +45 2815 4512. 
                        </p>
                        <h5 className="h5-md">When will I receive a call back?</h5>
                        <p className=" ">
                          Our team at LaegeKlar is available 24/7. We'll return your call as soon as possible.  
                        </p>
                        <h5 className="h5-md">How do I arrange a visit from a doctor?</h5>
                        <p className=" ">
                        Use our online form here, or contact us directly at +45 2815 4512.  
                        </p>
                        <h5 className="h5-md">Do I need to be a member?</h5>
                        <p className=" ">
                          Membership is not required to use our services.  
                        </p>
                        <h5 className="h5-md">How do I handle the payment?</h5>
                        <p className=" ">
                          You can easily pay online or over the phone using your Dankort or other credit cards.  
                        </p>
                        <h5 className="h5-md">How do I get an order confirmation?</h5>
                        <p>
                          After your online order, we will send a confirmation to your inbox.  
                        </p>


                        <h5 className="h5-md">What is the cost of a phone session?</h5>
                        <p>
                        For those with a Danish CPR number, the price is 400 kr from 8-16 and 500 kr 
                        outside these hours. Without a Danish CPR number, 
                        the price is 750 kr from 8-16 and 850 kr for the rest of the day.  
                        </p>
                        <h5 className="h5-md">Will my personal doctor be informed?</h5>
                        <p>
                          As part of LaegeKlar's procedure, we typically inform your own doctor about the treatment we provide. If you wish to opt out of this, please inform us during the call. 
                        </p>
                        <h5 className="h5-md">Your feedback</h5>
                        <p>
                         We value your feedback. If you have suggestions or concerns, feel free to share them with us. LaegeKlar follows the official complaint procedures of the Patient Safety Authority. 
                        </p>
                        <h5 className="h5-md">About our insurance</h5>
                        <p>
                         LaegeKlar is covered by Tryg Insurance.   
                        </p>

                        <h5 className="h5-md">Service terms</h5>
                        <p>
                         You can find all our terms and conditions <Link style={{color:"007bff !important",textDecoration:"underline"}} to={{pathname:"/terms-and-conditions"}}>here</Link>  
                        </p>
                        <h5 className="h5-md">Why is the CPR number necessary?</h5>
                        <p>
                          The law requires us to create a patient record based on your name and CPR number. All information is treated confidentially and securely. 
                        </p>
                        {/* <h5 className="h5-md">Contact us</h5>
                         */}
                        {/* <h5 className="h5-md">LaegeKlar</h5>
                        <p>
                          E-mail: <a href="mailto:info@laegeklar.dk">info@laegeklar.dk</a>  
                        </p>
                        <p>
                          Telefon: <a href="tel:28154512">2815 4512</a> 
                        </p> */}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FAQContent;
