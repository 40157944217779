import React from "react";
import axios from "axios";
const SuccessMsgContent = () => {
    function send_email()
    {
        let fields = localStorage.getItem("feilds");

        const newItem ={
            function_name    :"send_email",
            dct_fields       :fields
        };
        //process.env.REACT_APP_SERVER_URL
        axios.post(process.env.REACT_APP_SERVER_URL,newItem)
            .then(res=>{
                console.log(res.data);
            });
    }
    send_email();
    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 text-justify">
                        <h5>Your Appointment Booked Successfully!</h5>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SuccessMsgContent;
