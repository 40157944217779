import React from "react";
const ServicesContent = () => {
    
    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container faq">
                <div className="row">
                    <div className="col-12 my-3 text-center">
                        <h2 className="page-title">Services</h2>
                    </div>
                    <div className="col-12">
                        <ul className="dkk_list">
                            <li><strong>Immediate medical consultation:</strong> Should you require urgent medical attention or advice, our platform facilitates prompt communication with qualified professionals. </li>

                            <li><strong>Personalized health recommendations:</strong> Recognizing the unique health needs of each individual, we provide guidance tailored to your specific situation. </li>

                            <li><strong>Routine health guidance:</strong> With our expertise, we provide stable and reliable advice for daily health.  </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesContent;
