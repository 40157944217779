import React from "react";
const ServicesContent = () => {
    
    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container faq">
                <div className="row">
                    <div className="col-12 my-3 text-center">
                        <h2 className="page-title">Tjenester</h2>
                    </div>   
                    <div className="col-12">
                        <ul className="dkk_list">
                            <li><strong>Hurtig lægekonsultation:</strong> Hvis du har brug for hurtig lægehjælp eller rådgivning, gør vores platform det nemt at komme i kontakt med kvalificerede fagfolk. </li>

                            <li><strong>Personlig sundhedsrådgivning:</strong> Da alle har forskellige sundhedsbehov, giver vi råd, der passer specifikt til din situation.  </li>

                            <li><strong>Rutinemæssig sundhedsvejledning:</strong> Med vores ekspertise leverer vi stabil og pålidelig rådgivning til daglig sundhed.  </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ServicesContent;
