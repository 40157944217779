import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import $ from "jquery";


const DoctorCall = () => {
    
    let amount = 0;
    let request = "";
    function Localization(e)
    {
        // e.preventDefault();
        
        let date = new Date(); 
        let hh = date.getHours();

        let val = e.target.value;
        if(val=="dk")
        {
            $("#social_number").show();
            if(hh>=8 && hh<=16)
            {
               amount=25000;
                //   amount=100;
            }
            else
            {
                amount=30000;
                // amount=100;
            }
        }
        else
        {
            if(hh>=8 && hh<=16)
            {
                amount=60000;
                // amount=100;
            }
            else
            {
                amount=70000;
                // amount=100;

            }
            $("#social_number").hide();
        }

        request = {
            "order": {
            "items": [
              {
                "reference": "ref42",
                "name": "Læge aftale",
                "quantity": 1,
                "unit": "pcs",
                "unitPrice": 1,
                "grossTotalAmount": amount,
                "netTotalAmount": amount
              }
            ],
            "amount": amount,
            "currency": "DKK",
            "reference": "Læge aftale Order"
          },
            "checkout": {
                "language":"da-DK",
                "integrationType": "HostedPaymentPage",
                "returnUrl": "https://laegeklar.dk/dk/success-message",
                "termsUrl": "https://laegeklar.dk/dk/terms-and-conditions",
                "cancelUrl":"https://laegeklar.dk/dk/doctor-call",
                "appearance": {
                  "textOptions": {
                    "completePaymentButtonText": "Pay"
                  }
                },
                "consumer": {
                    "shippingAddress": {
                        "country": "DNK"
                    }
                },
                "shippingCountries": [
                    {
                        "countryCode": "DNK"
                    }
                ],
                "shipping": {
                    "countries": [
                        {
                            "countryCode": "DNK"
                        }
                    ]
                },
                "countryCode": "DNK"
            },
            
            "paymentMethodsConfiguration": [
                {
                    "name": "Card",
                    "enabled": true
                },
                {
                    "name": "MobilePay",
                    "enabled": true
                }
            ],
            "paymentMethods": [
                {
                    "name": "MobilePay"
                },
                {
                    "name": "Card"
                }
            ]
        };

        
    }
    $(document).ready(function(){
        $(".sn").on('input', function(ev){
            
            //Prevent default
            ev.preventDefault();
            
            //Remove hyphens
            let input = ev.target.value.split("-").join("");
            
            //Make a new string with the hyphens
            // Note that we make it into an array, and then join it at the end
            // This is so that we can use .map() 
            input = input.split('').map(function(cur, index){
                
                //If the size of input is 6 or 8, insert dash before it
                //else, just insert input
                if(index == 6)
                    return "-" + cur;
                else
                    return cur;
            }).join('');
            
            //Return the new string
            $(this).val(input);
        });
    });

    function confirmPayment(e)
    {
        e.preventDefault();
        let fields = $("#user_form").serialize();
        
        localStorage.setItem("feilds",fields);
        
        const newItem ={
            function_name    :"make_payment",
            fields:fields,
            lang:"dk",
            api_request:request
        };
        //process.env.REACT_APP_SERVER_URL
        axios.post(process.env.REACT_APP_SERVER_URL,newItem)
            .then(res=>{
                console.log(res.data);
                window.location = res.data;
            });
    }

    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container">
                <div className="row">
                    <div className="col-md-6 col-12">
                        <div className="form-container" style={{backgroundColor:"white", padding:"20px",borderRadius:"10px"}}>
                            <form id="user_form" method="POST" onSubmit={(e)=>confirmPayment(e)}>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Navn</label>
                                    <input type="text" name="user_name" className="form-control" required/>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Adresse</label>
                                    <input type="text" name="user_address" className="form-control" required/>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">E-mail</label>
                                    <input type="email" name="user_email" className="form-control" required/>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Telefonnummer</label>
                                    <input type="text" name="user_phone" className="form-control" required/>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Apotekets navn</label>
                                    <input type="text" name="pharmacy_name" className="form-control" required/>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Fødselsdato</label>
                                    <input type="date" name="dob" className="form-control" required/>
                                </div>
                                <div className="mb-3 col-12">
                                    <label className="form-label">Kommentarer</label>
                                    <textarea className="form-control" name="comments"></textarea>
                                </div>
                                <div className="mb-3 col-12">
                                    <div className="row" onChange={(e)=>Localization(e)}>
                                        <div className="col-md-6 col-12">
                                            <input type="radio" value={"dk"} name="localization"  required/>
                                            <label className="mx-2">Dansk</label>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <input type="radio" value={"none"} name="localization" required/>
                                            <label className="mx-2">Udenlandsk</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 col-12" id="social_number">
                                   <label className='form-label'>Socialnummer</label>
                                   <input type='text' 
                                          name='social_number' 
                                          className='sn form-control' 
                                          maxlength="11"
                                          placeholder="999999-9999"
                                          />
                                </div>
                                <div className="row">
                                    <div className="col-12 terms_condition">
                                        <div className="mb-5 term_condition_text">
                                            <input type="checkbox" className="text-left me-1" name="term_condition"/>
                                            <span><Link className="term_link" to={{pathname:"/dk/terms-and-conditions"}}>Vilkår og betingelser</Link></span>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-12 text-center payment-btn">
                                    <button type="submit">Betal nu</button>
                                </div>
                                <div className="mt-2 row">
                                    <div className="col-12">
                                        <div className="text-center cards_row">
                                            <img className="me-2" src={process.env.PUBLIC_URL+"/images/dkk.png"} style={{width:"7%"}}/>
                                            <img className="me-2" src={process.env.PUBLIC_URL+"/images/visa.png"} style={{width:"7%"}}/>
                                            <img className="me-2" src={process.env.PUBLIC_URL+"/images/mastercard.png"} style={{width:"7%"}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="payment-container">
                            <div className="bottom-border">
                               <h5 className="h5 h5-md">Pris</h5>
                               <p>Nye priser for vores ydelser
                               </p>
                            </div>
                            <div className="bold bottom-border">
                               <p>
                                Udlandske turister fra 8-16: 600 kr 
                                <br/>
                                Øvrige tidspunkter: 700 kr <img className="me-2" src={process.env.PUBLIC_URL+"/images/dkk.png"} style={{width:"7%"}}/>
                               </p>
                            </div>
                            <div className="bold bottom-border">
                               <p>
                                Danske borger med cpr-nr fra 8-16: 250 kr <img className="me-2" src={process.env.PUBLIC_URL+"/images/dkk.png"} style={{width:"7%"}}/>
                                <br/>
                                Øvrige tidspunkter: 300 kr <img className="me-2" src={process.env.PUBLIC_URL+"/images/dkk.png"} style={{width:"7%"}}/>
                               </p>
                            </div>
                           
                            
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
    );
};

export default DoctorCall;
