import { Link } from "react-router-dom";
import React from "react";

const Demo1Hero1 = () => {
    return (
        <section id="hero-1" className="bg-fixed hero-section division">
            <div className="container">
                <div className="row d-flex align-items-center">
                    {/* HERO TEXT */}
                    <div className="col-md-8 col-lg-7 col-xl-6">
                        <div className="hero-txt mb-40">
                            {/* Title */}
                            
                            <h2 className="">
                                {/* <span style={{color:"#3ca4ed"}}>Feel Free About</span>
                                <br></br>
                                <span style={{color:"#394d7f"}}>Finding Health-Center</span> */}
                            </h2>
                            {/* Text */}
                            <p>
                              When it comes to your health, help should be readily available. 
                              We present a solution that gives you easy and quick access to competent 
                              medical care for illness and health issues. Welcome to our unique approach, 
                              where you are always in direct contact with a doctor.
                            </p>
                            {/* Button */}
                            <Link to={{pathname:"/doctor-call"}}>
                                <a className="btn btn-blue blue-hover">
                                    Doctor Calls
                                </a>
                            </Link>
                        </div>
                    </div>
                    {/* END HERO TEXT */}
                    {/* HERO IMAGE */}
                    <div className="col-md-4 col-lg-5 col-xl-6">
                        <div className="hero-1-img text-center">
                            <img
                                className="img-fluid"
                                src="images/doc.png"
                                alt="Doctor"
                            />
                        </div>
                    </div>
                </div>
                {/* End row */}
            </div>
            {/* End container */}
        </section>
    );
};

export default Demo1Hero1;
