import React from "react";
import FAQ from "../dk_components/Demo-1/faq";
import Layouts from "../dk_layout/Layouts";
import {Helmet} from "react-helmet";
const FAQPage = () => {
  


  return (
    <Layouts header={1} footer={2}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Faq - Lægeklar.dk</title>
          <meta name="description" content="" />
      </Helmet>
      <FAQ />
    </Layouts>
  );
};

export default FAQPage;
