import React from "react";
import axios from "axios";
const AboutContent = () => {
    
    return (
        <section className="pt-100 pb-5 banner-section division" style={{backgroundColor:"#f6f6f6"}}>
            <div className="container about-page">
                <div className="row">
                    <div className="col-12">
                        <h4 className="h4-md">About laegeKlar</h4>
                        <p  className=" ">
                          Welcome to LaegeKlar, where your health guidance is our utmost priority. We aim to deliver health services right to your doorstep whenever you need them.  
                        </p>
                        <h4 className="h4-md">Our objective</h4>
                        <p className=" ">
                        By using digital technology, we aim to offer a slightly more convenient way to access health advice for everyone, no matter where they are. 
                        </p>
                        <h4 className="h4-md">Our services</h4>
                        <p className=" ">
                          LaegeKlar is your personal health channel. Boasting a wealth of knowledge and expertise, we stand ready to address your concerns, offer advice, and assist with health-related issues. Our services include: 
                        </p>
                        <ul className="dkk_list">
                            <li>
                            <strong>Instant Medical Access:</strong> We recognize that health doesn't follow a schedule. That’s why our platform ensures you can connect with a medical professional without delays whenever the need arises.  
                            </li>
                            <li>
                            <strong>Guidance Tailored to Your Needs:</strong> Whether you have urgent concerns or long-term health questions, we are here to guide and advise.
                            </li>
                            <li>
                            <strong>Routine Health Advice:</strong> Our medical team merges extensive expertise with a patient-centric approach.
                            </li>
                        </ul>
                        <h4 className="h4-md">Your well-being</h4>
                        <p className=" ">
                          Your health stands as our top priority. Our core mission is to ensure you feel supported, informed, and secure throughout your health journey.
                        </p>
                        <h4 className="h4-md">How it works</h4>
                        <p className=" ">
                          Using LaegeKlar is straightforward. Give us a call, and we'll address your needs, answer your queries, and guide you. If further steps are necessary, we're here to lead the way. 
                        </p>
                        <h4 className="h4-md">Our assurance to You</h4>
                        <ul className="dkk_list">
                            <li>Your health and safety are always at the forefront of our efforts.</li> 
                            
                            <li> We consistently provide services with integrity, ethics, and confidentiality.  </li>

                            <li> We stand by your side through every health challenge, big or small.  </li>
                        </ul>
                        <p className="mt-2">
                         Thank you for choosing LaegeKlar as your health consultation partner. We're eager to assist you in your seeking of optimal health.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutContent;
