import { useEffect, useState } from "react";
import { aTagClick, dataImage, stickyNav } from "../utils";
import Footer2 from "./Footer2";
import Header1 from "./Header1";

import $ from "jquery";
const Layouts = ({ children, header, footer }) => {
  const [toggle, setToggle] = useState(false);
  useEffect(() => {
    dataImage();
    aTagClick();
    window.addEventListener("scroll", stickyNav);
  });
  useEffect(() => {
    document.querySelector("body").classList.remove("wsactive");
  }, []);
  const openClick = () => {
    
    setToggle(!toggle);
    if(toggle==false)
    {
      $(".wsmegamenu").hide();
      $(".wsmenu-click").removeClass("ws-activearrow");
      $(".sub-menu").hide();
    }
    document.querySelector("body").classList.toggle("wsactive");
  };
  const getHeader = () => {
    switch (header) {
      case 1:
        return <Header1 toggleMenu={() => openClick()} toggle={toggle} />;
    }
  };
  const getFooter = () => {
    switch (footer) {
      case 2:
        return <Footer2 />;
    }
  };
  return (
    <div id="page" className="page-wrapper demo">
      {getHeader()}
      {/* <MobileMenu /> */}
      {children}
      {getFooter()}
    </div>
  );
};

export default Layouts;
