import { Link,useLocation } from "react-router-dom"
import React, { useState,useEffect } from "react"
import $ from "jquery";
import i18n from "i18next";
import { useTranslation } from "react-i18next";


const MobileMenu = ({toggleMenu}) => {
  
  const {t} = useTranslation();
  const logo = t('logo',{ returnObjects: true });

  const [activeMenu, setActiveMenu] = useState("");
  const activeMenuSet = (value) =>
      setActiveMenu(activeMenu === value ? "" : value),
    activeLi = (value) =>
      value === activeMenu ? { display: "block" } : { display: "none" },
    activeArrow = (value) => (value === activeMenu ? "ws-activearrow" : "");
  function toggleDepartment()
  {
    $(".wsmegamenu").hide();
    $(".wsmenu-click").removeClass("ws-activearrow");
    toggleMenu();
  }


  const location = useLocation();
  const { from } = (location.state)?location.state:"";
  
  const [newUrl,setnewUrl] = useState("");

  useEffect(()=>{
    let url = window.location.href;
    let path = new URL(url).pathname;
    let index = path.split("/");
    
    
    let new_url = "";
    
    index = index[index.length-1];
    if(index!="")
    {
      new_url = "/dk/"+index;
    }
    else
    {
      new_url = "/dk";
    }
    

    setnewUrl(new_url);
  },[from])


  return (
    <div
      className="wsmainfull menu clearfix cloned d-block d-lg-none"
      style={{
        position: "fixed",
        top: 0,
        marginTop: 0,
        zIndex: 500,
        display: "block",
        left: 0,
        width: 731,
      }}
    >
      <div className="wsmainwp clearfix">
        {/* LOGO IMAGE */}
        {/* For Retina Ready displays take a image with double the amount of pixels that your image will be displayed (e.g 360 x 80 pixels) */}
        <div className="smllogo">
          <a href="#hero-2">
          <Link to={{pathname:"/"}} style={{fontWeight:"bold"}}>
              <span style={{color:"#37a5ee",fontSize:"2rem"}}>Laege</span>
              <span style={{color:"#394d7f",fontSize:"2rem"}}>klar</span>
              <span style={{fontSize:"1rem !important"}}>.dk</span>
              
            </Link>
          </a>
        </div>
        {/* MAIN MENU */}
        <nav className="wsmenu clearfix" style={{ height: 360,marginTop:71 }}>
          {/* <div className="overlapblackbg" /> */}
          <ul className="wsmenu-list">
            {/* DROPDOWN MENU */}
            <li className="nl-simple" aria-haspopup="true">
                <Link to={{pathname:"/"}}>
                  <a>Home</a>
                </Link>
            </li>
            <li className="nl-simple" aria-haspopup="true">
                <Link to={{pathname:"/about-us"}}>
                  <a>About</a>
                </Link>
            </li>
            <li className="nl-simple" aria-haspopup="true">
                <Link to={{pathname:"/services"}}>
                  <a>Services</a>
                </Link>
            </li>
            <li className="nl-simple" aria-haspopup="true">
                <Link to={{pathname:"/faq"}}>
                  <a>FAQ</a>
                </Link>
            </li>
            <li className="nl-simple" aria-haspopup="true">
                <Link  to={{pathname:newUrl}}>
                  <a>Danish</a>
                </Link>
              </li>
            <li className="nl-simple" aria-haspopup="true">
                <Link to={{pathname:"/doctor-call"}}>
                  <a>Contact</a>
                </Link>
            </li>
            {/* END DROPDOWN MENU */}
            {/* PAGES */}
            
          </ul>
        </nav>{" "}
        {/* END MAIN MENU */}
      </div>
    </div>
  );
};

export default MobileMenu;
