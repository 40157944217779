import React,{useEffect,useState} from "react";
import Demo1Hero1 from "../components/Demo-1/Demo1Hero-1";
import Layouts from "../layout/Layouts";
import {Helmet} from "react-helmet";
const Demo1 = () => {
  
  let Width = window.innerWidth;
  const [deviceWidth,setDeviceWidth] = useState(0);
  
  useEffect(()=>{
    setDeviceWidth(window.innerWidth);
  },[deviceWidth]);

  return (
    <Layouts header={1} footer={2}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Lægeklar.dk</title>
          <meta name="description" content="" />
      </Helmet>
      <Demo1Hero1 />
    </Layouts>
  );
};

export default Demo1;
